import React, { ReactNode, useState } from 'react';
import { GraphQLClient, request } from 'graphql-request';
import { SWRConfig } from 'swr';
import { signOut } from 'next-auth/client';

export default function SWR(props: { children: ReactNode }) {
  let [client] = useState(() => new GraphQLClient('/api/graphql'));

  return (
    <SWRConfig
      value={{
        async fetcher(gql, variables) {
          try {
            return await client.request(gql, variables);
          } catch (e) {
            let response = e.response;
            if (
              response?.errors?.find((e: any) => e.message === 'Unauthorized')
            ) {
              await signOut();
            }
          }
        }
      }}
    >
      {props.children}
    </SWRConfig>
  );
}

export function mutate<
  Data extends Record<string, any>,
  Variables extends Record<string, any>
>(gql: string, variables?: Variables) {
  return request<Data, Variables>('/api/graphql', gql, variables);
}
